
import { createColumn, formatAsCurrency } from '@/util/table';
import { handleErrorUI, handleSuccessUI } from '@/util/error';
import { OrderStatus } from '@/repositories/OrderRepository';
import { InitialOrderModalMode } from '@/components/Tools/Order/OrderModalModes';
import PaginationMixin from '@/mixins/PaginationMixin';
import RequestOrder from '@/models/RequestOrder';
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [CommonMixin, PaginationMixin],
  data() {
    return {
      data: [],
      loading: true,
      appends: [
        createColumn(
          'grandTotal',
          'Request Grand Total',
          formatAsCurrency(row => row.currency.code)
        )
      ],
      showDialog: false,
      mode: InitialOrderModalMode.VIEW,
      selectedOrder: {}
    };
  },
  async created() {
    await this.fetchOrders();
  },
  methods: {
    showDetailDialog(order: RequestOrder) {
      this.selectedOrder = order;
      this.showDialog = true;
    },
    showViewDialog(order: RequestOrder) {
      this.mode = InitialOrderModalMode.VIEW;
      this.showDetailDialog(order);
    },
    showProceedDialog(order: RequestOrder) {
      this.mode = InitialOrderModalMode.SUGGEST;
      this.showDetailDialog(order);
    },
    async saveSuggestion(order: RequestOrder) {
      try {
        await this.$repo.order.createOrderSuggestion(order);
        this.showDialog = false;
        this.data = this.data.filter(entry => entry.id !== order.id);
        handleSuccessUI(
          `Suggestion for order from ${order.company} has been successfully saved`
        );
      } catch (err) {
        handleErrorUI(err);
      }
    },
    async fetchOrders() {
      this.loading = true;
      try {
        const { tenant } = await this.$repo.session.fetch();
        const { data, pagination } = await this.$repo.order.getOrderWithStatus(
          tenant,
          OrderStatus.WAITING_FOR_REVIEW,
          this.pagination
        );
        this.data = data;
        this.updatePagination(pagination);
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async paginationChangeHandler() {
      await this.fetchOrders();
    }
  }
};
